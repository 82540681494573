@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local("Inter Thin"),
       url("../fonts/Inter/Inter-Thin.ttf")
       format("truetype");
  }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local("Inter ExtraLight"),
       url("../fonts/Inter/Inter-ExtraLight.ttf")
       format("truetype");
  }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Inter Light"),
       url("../fonts/Inter/Inter-Light.ttf")
       format("truetype");
  }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Inter Regular"),
       url("../fonts/Inter/Inter-Regular.ttf")
       format("truetype");
  }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Inter Medium"),
       url("../fonts/Inter/Inter-Medium.ttf")
       format("truetype");
  }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Inter SemiBold"),
       url("../fonts/Inter/Inter-SemiBold.ttf")
       format("truetype");
  }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Inter Bold"),
       url("../fonts/Inter/Inter-Bold.ttf")
       format("truetype");
  }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local("Inter ExtraBold"),
       url("../fonts/Inter/Inter-ExtraBold.ttf")
       format("truetype");
  }

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local("Inter Black"),
       url("../fonts/Inter/Inter-Black.ttf")
       format("truetype");
  }
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-display: swap;
    font-weight: 200;
    src: local("Manrope ExtraLight"),
         url("../fonts/Manrope/Manrope-ExtraLight.ttf")
         format("truetype");
  }

@font-face {
    font-family: "Manrope";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local("Manrope Light"),
         url("../fonts/Manrope/Manrope-Light.ttf")
         format("truetype");
  }

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Manrope Regular"),
       url("../fonts/Manrope/Manrope-Regular.ttf")
       format("truetype");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Manrope Medium"),
       url("../fonts/Manrope/Manrope-Medium.ttf")
       format("truetype");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Manrope SemiBold"),
       url("../fonts/Manrope/Manrope-SemiBold.ttf")
       format("truetype");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Manrope ExtraBold"),
       url("../fonts/Manrope/Manrope-Bold.ttf")
       format("truetype");
}

@font-face {
    font-family: "Manrope";
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: local("Manrope Bold"),
         url("../fonts/Manrope/Manrope-ExtraBold.ttf")
         format("truetype");
  }

#root {
  position: relative;
  max-width: 100%;
  width: 100%;
  overflow: auto;
}
p{
  margin: 0;
}

.styled-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #B7B9DA;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #FB0F42;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #FB0F42;
  }
}

.auth-layout{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #F3F5F8;

  .login-container {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    gap: 30px;
    margin-top: 60px;
    .auth-header{
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      margin-bottom: 20px;
      &.full-logo{
        .logo {
          display: flex;
          width: 288.749px;
          height: 70px;
          padding: 0px 6.31px 0.003px 0px;
          align-items: center;
          justify-content: center;
          svg{
            height: 70px;
            width: 288px;
          }
        }
      }
      .subtitle{
        margin: 0;
        color: var(--color-palette-grey-100, #1F2023);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.18px;
        .highlight{
          color: var(--color-palette-grey-100, #1F2023);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.18px;
        }
      }
    }

    .auth-footer{
      z-index: 1;
      display: inline-flex;
      align-items: center;
      gap: 10px;
      .patented-logo{
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    width: 500px;
    height: 485px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 50px;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(16.5px);
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    .title-header{
      font-family: Manrope;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.015em;
      color: #1F2023;
      margin: 0px 0px 5px 0px;
    }
    .custom-stack{
      width: 100%;
      padding-bottom: 20px;
    }
    .sub-title-header{
      font-family: Inter;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: -0.012em;
      color: #737373;
      margin: 0%;
      margin-bottom: 30px;
      &.final-step{
        margin: 0;
      }
    }
    .comment{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      display: flex;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: #1F2023;
      align-items: center;
      gap: 10px;
      margin-bottom: 25px;
      .twitter-at{
        color: #DF1440;
      }
    }
    .button{
      width: 100%;
      height: 50px;
      border: 1px solid #DEDEDE;
      border-radius: 8px;
      outline: none;
      cursor: pointer;
      font-family: Manrope;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.012em;
      color: #0E1F40;
      margin: 5px;
    }
    .button-action{
      width: 100%;
      height: 50px;
      outline: none;
      cursor: pointer;
      font-family: Manrope;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.012em;
      margin-bottom: 45px;
      background: #DF1440;
      text-transform: none;
      border: 1px solid #DF1440;
      box-shadow: 0px 25px 33px rgba(251, 15, 66, 0.3);
      border-radius: 6px;
    }
    .divider{
      width: 100%;
      height: 1px;
      opacity: 0.1;
      background: #1F2023;
      margin: 30px 0px;
      &.final-step{
        margin: 50px 0px;
      }
    }
    &.personal-content{
      padding: 45px 50px 0px;
      height: fit-content;
    }
    .redirect-countdown{
      color: var(--invalidate-ui-contrast-red, #DF1440);
      text-align: center;
      font-family: Manrope;
      font-size: 11px;
      font-weight: 700;
      line-height: 12.767px; /* 116.061% */
      letter-spacing: 2.2px;
      text-transform: uppercase;
    }
  }

  .backgroundSignInLine{
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1;
  }

}

.app-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #F3F5F8;

  .patent-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;

    .patent-menu{
      position: absolute;
      width: 290px;
      height: 640px;
      left: 11px;
      top: calc(50% - 640px/2);
      background: #FFFFFF;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-grow: 1;
      justify-content: center;
        .filter{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          width: 254px;
          height: 239px;
          .filters-option-button{
            width: 254px;
            height: 35px;
            background: #FFFFFF;
            border: 1px solid #DDDEDE;
            border-radius: 4px;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            letter-spacing: -0.01em;
            color: #1F2023;
            text-transform: capitalize;
            margin: 5px;
            width: 100%;
          }
          .filters-option-button-selected{
            width: 254px;
            height: 35px;
            background: rgba(223, 20, 64, 0.15);
            border: 1.5px solid #FB0F42;
            border-radius: 4px;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            letter-spacing: -0.01em;
            color: #1F2023;
            text-transform: capitalize;
            margin: 5px;
            width: 100%;
          }
        }
        .filter-date-container{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          width: 254px;
          height: 66px;
          .filters-date{
            display: flex;
            align-items: center;
            gap: 10px;
            .custom-datepicker {
              height: 14px;
            }
            .custom-datepicker input {
              height: 10px;
              line-height: 14px;
            }
          }
        }
        .search-button-smart-filter{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 15px 27px;
          gap: 12px;
          width: 254px;
          height: 50px;
          background: #DF1440;
          border: 1px solid #DF1440;
          box-shadow: 0px 67px 27px rgba(195, 19, 57, 0.03), 0px 38px 23px rgba(195, 19, 57, 0.11), 0px 17px 17px rgba(195, 19, 57, 0.18), 0px 4px 9px rgba(195, 19, 57, 0.21), 0px 0px 0px rgba(195, 19, 57, 0.22);
          border-radius: 8px;
          text-transform: capitalize;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.01em;
          color: #FFFFFF;
        }
        .filter-overlap-container{
          display: flex;
          margin-top: 15px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          width: 254px;
          height: 66px;
          margin-bottom: 10px;
          .options{
            width: 254px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }
        }
    }
    .patent-content-confirm{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 20px 50px;
      box-sizing: border-box;
      .patent-header-border{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
        background: rgba(255, 255, 255, 0.25);
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        .patent-information-header{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 15px 20px;
          gap: 4px;
          background: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 12px;
          .patent-publication-number{
            font-family: Manrope;
            font-weight: 500;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: 0.1em;
            color: #FB0F42;
          }
          .patent-title{
            font-family: 'Manrope';
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            /* identical to box height, or 133% */

            display: flex;
            align-items: center;
            letter-spacing: -0.01em;

            /* Invalidate Brand/Dark Grey (brand) */

            color: #1F2023;
            display: flex;
            gap: 50px;
            flex-direction: row;
            align-items: center;
            margin: 5px;
          }
        }
      }
      .search-button-smart-filter{
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        padding: 15px 27px;
        gap: 12px;
        width: 254px;
        height: 50px;
        border: 1.5px solid #DF1440;
        text-transform: capitalize;
        font-family: 'Inter';
        font-size: 16px;
        line-height: 19px;
      }
      .inventor-patents-button{
        border-radius: 5px;
      }
      .search-button-smart-filter{
        box-shadow: 0px 67px 27px rgba(195, 19, 57, 0.03), 0px 38px 23px rgba(195, 19, 57, 0.11), 0px 17px 17px rgba(195, 19, 57, 0.18), 0px 4px 9px rgba(195, 19, 57, 0.21), 0px 0px 0px rgba(195, 19, 57, 0.22);
        border-radius: 8px;
        text-transform: capitalize;
        font-family: 'Inter';
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
      }
      .cta-row{
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        #go-back-button{
          width: 124px;
          height: 27px;
          border-radius: 5px;
          font-weight: 600;
        }
      }
      .button-common{
        border-radius: 5px;
        width: 200px;
        height: 50px;
        text-transform: capitalize;
        font-family: 'Inter';
        font-size: 16px;
        line-height: 19px;
      }
      .reset-search-button{
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        justify-content: center;
        align-items: center;
        padding: 6px 14px;
        gap: 8px;
        width: 124px;
        height: 27px;
        border: 1.5px solid #DF1440;
        border-radius: 5px;
        font-family: 'Inter';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: #DF1440;
      }
      .card-content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
        min-height: 297px;
        max-height: 350px;
        background: #FFFFFF;
        border-radius: 20px;
        flex: none;
        order: 1;
        flex-grow: 0;
        .content{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          padding: 8px 30px 8px 20px;
          isolation: isolate;
          box-shadow: 0px 118px 47px rgba(0, 0, 0, 0.01), 0px 66px 40px rgba(0, 0, 0, 0.03), 0px 29px 29px rgba(0, 0, 0, 0.05), 0px 7px 16px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06);
          background: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 15px;
          overflow: hidden;
          overflow-y: scroll;


          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #B7B9DA;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #FB0F42;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #FB0F42;
          }

          .element {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            padding: 18px 0px;
            border-bottom: 1px solid rgba(14, 31, 64, 0.15);
            width: 100%;

            .number {
              background: #FADCE2;
              border-radius: 100px;
              color: #DF1440;
              min-width: 25px;
              height: 25px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: 14px;
            }
            .claim {
              font-size: 11px;
            }
          }
          .subelement {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            padding: 18px 0px 18px 30px;
            border-bottom: 1px solid rgba(14, 31, 64, 0.15);

            .number {
              background: #DDDEDE;
              border-radius: 100px;
              color: #1F2023;
              min-width: 25px;
              height: 25px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: 11px;
            }
            .claim {
              font-size: 11px;
            }
          }
        }
        .patent-card-publication-number{
          font-family: 'Manrope';
          font-weight: 500;
          font-size: 21px;
          line-height: 28px;
          letter-spacing: 0.1em;
          color: #FB0F42;
        }
        .patent-card-title{
          font-family: 'Manrope';
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01em;
          color: #1F2023;
        }
        .patent-card-info{
          font-family: 'Inter';
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 20px;
          .patent-card-date{
            min-width: fit-content;
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #8F8F91;
          }
          .patent-card-authors{
            font-family: 'Inter';
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #302F37;
          }
          .patent-card-info-assignee{
            font-family: 'Inter';
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #DF1440;
          }
          svg{
            margin-right: 8px;
            min-width: fit-content;
          }
        }
        .patent-card-abstract{
          font-family: 'Inter';
          font-weight: 400;
          font-size: 13px;
          line-height: 150%;
          text-align: justify;
          color: #8F8F91;
          text-overflow: ellipsis;
        }
      }
      .patent-card{
        overflow-y: auto;
        width: 580px;
        padding: 35px;
        box-sizing: border-box;
      }

    .confirm-search-content{
      padding: 20px 150px;
    }

    .inventor-patent-list{
      overflow-y: auto;
      gap: 20px;
      .inventor-patent{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .row{
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg{
            margin-right: 8px;
          }
          .patent-title{
            font-family: 'Manrope';
            font-weight: 700;
            font-size: 15px;
            color: #1F2023;
            text-align: left;
          }
          .publication-number{
            padding: 5px 8px;
            min-width: fit-content;
            background: rgba(251, 15, 66, 0.07);
            border-radius: 6px;
            font-family: 'Manrope';
            font-weight: 600;
            font-size: 14px;
            text-align: right;
            letter-spacing: 0.05em;
            color: #DF1440;
          }
          .assignee{
            display: flex;
            align-items: center;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 12px;
            color: #8F8F91;
          }
          .publication-date{
            display: flex;
            align-items: center;
            font-family: 'Inter';
            font-weight: 700;
            font-size: 9px;
            letter-spacing: 0.06em;
            color: #1F2023;
          }
        }
      }
    }

    }
    #confirm-search{
      position: inherit;
    }

    .content {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      padding: 12px;
      box-sizing: border-box;
      align-items: center;
      width: 1000px;
      height: 650px;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 25px;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
      position: relative;
      .title-header{
        font-family: Manrope;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        letter-spacing: -0.015em;
        color: #1F2023;
        margin: 5px;
        display: flex;
        gap: 75px;
      }
      .sub-title-header{
        font-family: Inter;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.012em;
        color: #1F2023;
        margin: 0%;
        margin-bottom: 10px;
        .inventor-name{
          color: #DF1440;
          font-weight: 500;
        }
      }
      .cta-row{
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        .button-common{
          width: 200px;
          height: 50px;
        }
        .inventor-patents-button{
          border-radius: 5px;
        }
      }
      .search-button-smart-filter{
        box-shadow: 0px 67px 27px rgba(195, 19, 57, 0.03), 0px 38px 23px rgba(195, 19, 57, 0.11), 0px 17px 17px rgba(195, 19, 57, 0.18), 0px 4px 9px rgba(195, 19, 57, 0.21), 0px 0px 0px rgba(195, 19, 57, 0.22);
        border-radius: 8px;
        text-transform: capitalize;
        font-family: 'Inter';
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
      }
      .textfield{
        width: 450px;
        height: 75px;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 118px 47px rgba(0, 0, 0, 0.01), 0px 66px 40px rgba(0, 0, 0, 0.03), 0px 29px 29px rgba(0, 0, 0, 0.05), 0px 7px 16px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06);
        border-radius: 15px;
      }
      .search-button{
        width: 355px;
        height: 50px;
        outline: none;
        cursor: pointer;
        font-family: Manrope;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.012em;
        color: #0E1F40;
        margin-top: 30px;
        background: #DF1440;
        color: #fff;
        text-transform: none;
        border: 1px solid #DF1440;
        box-shadow: 0px 67px 27px rgba(195, 19, 57, 0.03), 0px 38px 23px rgba(195, 19, 57, 0.11), 0px 17px 17px rgba(195, 19, 57, 0.18), 0px 4px 9px rgba(195, 19, 57, 0.21), 0px 0px 0px rgba(195, 19, 57, 0.22);
        border-radius: 8px;
      }
      .search-button.disabled{
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
        border: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: none;
      }
      .comment{
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #1F2023;
        .highlight{
          color: #8F8F91;
        }
      }
      .inventor-input{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-top: 40px;
      }
      .generate-prior-art-menu{
        padding: 20px 16px 20px 16px;
        box-sizing: border-box;
        width: 290px;
        height: 100%;
        background: #FFFFFF;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-grow: 1;
        justify-content: flex-start;
        .menu-title-header{
          font-family: 'Manrope';
          font-weight: 600;
          font-size: 22px;
          line-height: 32px;
        }
        .logo-divider{
          width: calc(100% + 32px);
          margin-left: -16px;
        }
        .menu-sub-title-header{
          font-family: 'Inter';
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
        }
        .comment{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 16px;
          gap: 13px;
          width: 254px;
          height: 62px;
          box-sizing: border-box;
          margin-top: auto;
          background: rgba(250, 220, 226, 0.55);
          border-radius: 12px;
          .icon{
            width: 20px;
            height: 20px;
          }
          .text{
            font-family: 'Manrope';
            font-weight: 800;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #FB0F42;
          }
        }
        .search-button-smart-filter{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 15px 27px;
          gap: 12px;
          width: 254px;
          height: 50px;
          background: #DF1440;
          border: 1px solid #DF1440;
          box-shadow: 0px 67px 27px rgba(195, 19, 57, 0.03), 0px 38px 23px rgba(195, 19, 57, 0.11), 0px 17px 17px rgba(195, 19, 57, 0.18), 0px 4px 9px rgba(195, 19, 57, 0.21), 0px 0px 0px rgba(195, 19, 57, 0.22);
          border-radius: 8px;
          text-transform: capitalize;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.01em;
          color: #FFFFFF;
        }
      }
    }

    .generate-prior-art-content{
      flex-direction: row;
    }
  }

  .prior-art-list-container {
    flex-grow: 1;
    z-index: 1;
    overflow: hidden;
    .content {
      display: flex;
      gap: 10px;
      padding: 10px;
      height: calc(100vh - 20px);
      overflow: hidden;
      .title{
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        gap: 15px;
        align-items: center;
        letter-spacing: -0.01em;
      }
      .patent-menu{
        width: 400px;
        box-sizing: border-box;
        border-radius: 20px;
        border: 2px solid white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        padding: 20px 20px 7px 20px;
        background: #FFF;
        backdrop-filter: blur(50px);
        .menu-header {
          display: flex;
          font-family: 'Manrope';
          font-weight: 700;
          font-size: 9px;
          align-items: flex-end;
          gap: 8px;
          color: #a7a8a8;
        }
        .text-link {
          cursor: pointer;
          text-transform: uppercase;
          font-family: Inter;
          font-weight: 700;
          font-size: 10px;
          line-height: 14px;
          display: flex;
          align-items: center;
          letter-spacing: 0.12em;
          color: #DF1440;
          margin: 10px 5px;
        }
        .filter-header{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
          padding: 10px 0px;
          .filter-text{
            font-family: 'Manrope';
            font-weight: 700;
            font-size: 21px;
            line-height: 26px;
            letter-spacing: -0.01em;
            color: #1F2023;
          }
        }
        .header-divider{
          width: calc(100% + 40px);
          min-height: 1px;
          opacity: 0.1;
          background: #1F2023;
          margin: 10px -20px;
        }
        .divider{
          width: 100%;
          min-height: 1px;
          opacity: 0.1;
          background: #1F2023;
          margin: 10px 0px;
        }
        .filter-date-container{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          width: 254px;
          .filters-date{
            display: flex;
            align-items: center;
            gap: 10px;
            .custom-datepicker {
              background-color: #FFFFFF;
              //   height: 14px;
              //   .MuiInputBase-root {
              //     height: 34px;
              //     line-height: 14px;
            }
            // .custom-datepicker .MuiFormLabel-root {
            //     transform: translate(0, 1.5px) scale(1)
            //   }
          }
        }
        .search-button-smart-filter{
          display: flex;
          margin-top: auto;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 15px 27px;
          gap: 12px;
          width: 254px;
          height: 50px;
          background: #DF1440;
          border: 1px solid #DF1440;
          box-shadow: 0px 67px 27px rgba(195, 19, 57, 0.03), 0px 38px 23px rgba(195, 19, 57, 0.11), 0px 17px 17px rgba(195, 19, 57, 0.18), 0px 4px 9px rgba(195, 19, 57, 0.21), 0px 0px 0px rgba(195, 19, 57, 0.22);
          border-radius: 8px;
          text-transform: capitalize;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.01em;
          color: #FFFFFF;
        }
        .filter-overlap-container{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          margin-bottom: 10px;
          .options{
            width: 254px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }
        }
      }
      .list{
        z-index: 1;
        overflow: hidden;
        backdrop-filter: blur(50px);
        width: 100%;
        .top-area{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .title-header{
            font-family: 'Manrope';
            font-weight: 600;
            font-size: 25px;
            letter-spacing: -0.01em;
            .patent-number{
              padding: 0px 10px;
              margin-left: 12px;
              background: rgba(251, 15, 66, 0.07);
              border-radius: 6px;
              font-size: 22px;
              letter-spacing: 0.05em;
              color: #DF1440;
            }
          }
          .actions{
            display: flex;
            flex-direction: row;
            gap: 14px;

            button{
              text-transform: capitalize;
            }
          }
          .subtitle{
            font-family: 'Inter';
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            /* identical to box height, or 131% */

            display: flex;
            align-items: flex-end;
            text-align: center;
            letter-spacing: -0.01em;

            color: #1F2023;
          }
        }
        .claim-top-area{
          flex-direction: column;
        }
        .claims-table{
          border-right: 1px solid rgba(138, 157, 177, 0.25);
          border-bottom: 1px solid rgba(138, 157, 177, 0.25);
          border-left: 1px solid rgba(138, 157, 177, 0.25);
          background: #FFF;
        }
        #productName{
          font-family: 'Manrope';
          font-weight: 700;
          font-size: 15px;
          line-height: 22px;
        }
        #companyName{
          font-family: 'Inter';
          font-weight: 500;
          font-size: 13px;
          line-height: 22px;
          color: #4B4B4B;
        }
        #releaseDate{
          font-family: 'Inter';
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          /* identical to box height, or 154% */

          text-align: center;

          /* Invalidate UI/Grey 700 */

          color: #4B4B4B;
        }
        #productIndustry{
          p {
            padding: 4px 10px;
            margin: 0;
            width: fit-content;
            height: 23px;

            background: #FDF4F7;
            border-radius: 55px;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 11px;
            line-height: 20px;
            color: #DF1440;
          }
        }
        #claimMatch{
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          vertical-align: top;
          padding-top: 28px;
          .bingo-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            .bingo{
              overflow-y: auto;
              max-height: 90px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              width: 140px;
              gap: 8px;
              .bingo-item{
                font-family: 'Manrope';
                font-weight: 700;
                font-size: 11px;
                line-height: 15px;
                /* identical to box height, or 136% */

                text-align: center;

                color: #FFFFFF;

                mix-blend-mode: normal;
                text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.45);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px 0px 1px;
                gap: 10px;

                width: 22px;
                height: 22px;

                background: #D1DDE3;
                border-radius: 555px;
              }
              .green-bg{
                background: #04C45C;
              }
            }
          }
        }

        #claimNumber{
          span{
            display: flex;
            width: 28px;
            height: 28px;
            padding-bottom: 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12.727px;
            flex-shrink: 0;
            box-sizing: border-box;
          }
          &.selected{
            span{
              border-radius: 706.364px;
              background: var(--color-palette-grey-100, #1F2023);
              color: var(--color-palette-white, #FFF);
              text-align: center;
              font-family: Manrope;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 19.091px; /* 136.364% */
            }
          }
          &.not-selected{
            span{
              border-radius: 706.364px;
              border: 1.273px solid var(--color-palette-grey-33, #B5B5B6);
              color: var(--color-palette-grey-100, #1F2023);
              text-align: center;
              font-family: Manrope;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 19.091px; /* 136.364% */
            }
          }
        }
        #claimContent{
          font-family: Courier;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          min-height: 36px;
          &.selected{
            color: #1F2023;
          }
          &.not-selected{
            color: #8F8F91;
          }
          display: flex;
          padding: 6px 12px;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          flex: 1 0 0;
          .sub-element{
            display: flex;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            .sub-element-id{
              color: var(--color-palette-grey-100, #1F2023);
text-align: center;
font-family: Manrope;
font-size: 11px;
font-style: normal;
font-weight: 700;
line-height: 15px; /* 136.364% */
              display: flex;
              min-width: 22px;
              min-height: 22px;
              padding-bottom: 0px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 555px;
              background: var(--color-palette-grey-15, #DDDEDE);
            }
          }
        }
      }
      .detail{
        z-index: 1;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 40px;
        display: flex;
        flex-grow: 1;
        gap: 30px;
        flex-direction: column;
        .title-header{
          font-family: 'Manrope';
          font-weight: 600;
          font-size: 25px;
          letter-spacing: -0.01em;
        }
        .info-container{
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          align-self: stretch;
          .info-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid #DDDEDE;
            .product-tag{
              display: flex;
              align-items: center;
              gap: 20px;
              .product-name{
                color: var(--color-palette-grey-100, #1F2023);
                font-family: Manrope;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: -0.3px;
              }
            }
          }
          .quick-info-row{
            display: flex;
            align-items: center;
            gap: 45px;
            .info{
              display: flex;
              align-items: center;
              gap: 8px;
              color: var(--color-palette-grey-100, #1F2023);
              font-family: Inter;
              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: 14px;
              letter-spacing: 0.8px;
              text-transform: uppercase;
              .highlight{
                color: var(--invalidate-palette-red-dark, #DF1440);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Inter;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px; /* 140% */
                letter-spacing: 0.8px;
                text-transform: uppercase;
              }
            }
          }
          .add-to-matter-row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            .source{
              display: flex;
              align-items: center;
              align-self: stretch;
              text-transform: uppercase;
              color: var(--color-palette-grey-100, #1F2023);
              span{
                font-weight: 700;
              }
              font-weight: 500;
              font-family: Inter;
              font-size: 10px;
              font-style: normal;
              line-height: 14px;
              letter-spacing: 0.8px;
              text-transform: uppercase;
            }
            .add-to-matter-button{
              border-radius: 8px;
              border: 1px solid var(--color-palette-red-dark, #DF1440);
              background: var(--color-palette-red-dark, #DF1440);
              box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.22), 0px 4px 9px 0px rgba(195, 19, 57, 0.21), 0px 17px 17px 0px rgba(195, 19, 57, 0.18), 0px 38px 23px 0px rgba(195, 19, 57, 0.11), 0px 67px 27px 0px rgba(195, 19, 57, 0.03), 0px 105px 29px 0px rgba(195, 19, 57, 0.00);
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              letter-spacing: 0.14px;
            }
          }
          .stretch-row{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            align-self: stretch;
            .reason{
              display: flex;
              flex-direction: column;
              align-self: stretch;
              color: var(--color-palette-grey-100, #1F2023);
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              line-height: 22px;
            }
            .patent-name{
              color: var(--color-palette-grey-100, #1F2023);
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Manrope;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 32px; /* 133.333% */
              letter-spacing: -0.24px;
              border-bottom: 1px solid var(--color-palette-grey-15, #DDDEDE);
              padding-bottom: 20px;
              align-self: stretch;
            }
          }
        }
        .use-case-wrapper{
          background: var(--color-palette-red-5, #FDF3F5);
          border-radius: 14px;
          padding: 10px;
          width: 100%;
          box-sizing: border-box;
          .use-case-card{
            display: flex;
            flex-direction: column;
            padding: 20px;
            align-items: flex-start;
            gap: 10px;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid var(--color-palette-grey-15, #DDDEDE);
            background: #FFF;
            box-sizing: border-box;
            .use-case-label{
              color: var(--color-palette-red-dark, #DF1440);
              font-family: Manrope;
              font-size: 18px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: -0.18px;
            }
            .use-case-text{
              display: flex;
              flex-direction: column;
              align-self: stretch;
              color: var(--color-palette-grey-75, #505154);
              text-align: justify;
              font-family: Inter;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              p {
                margin: 5px 0px;
              }
              .diagram{
                position: relative;
                height: 250px;
                overflow: hidden;
                .diagram-button{
                  position: absolute;
                  bottom: 20px;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 2;
                  display: inline-flex;
                  padding: 11px 27px 12px 27px;
                  justify-content: center;
                  align-items: center;
                  gap: 12px;
                  border-radius: 8px;
                  border: 1px solid var(--invalidate-palette-red-dark, #DF1440);
                  background: var(--invalidate-palette-red-dark, #DF1440);
                  box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.22), 0px 4px 9px 0px rgba(195, 19, 57, 0.21), 0px 17px 17px 0px rgba(195, 19, 57, 0.18), 0px 38px 23px 0px rgba(195, 19, 57, 0.11), 0px 67px 27px 0px rgba(195, 19, 57, 0.03), 0px 105px 29px 0px rgba(195, 19, 57, 0.00);
                }
                .fade{
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  flex-shrink: 0;

                  background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
                }
              }
            }
          }
        }
        .tabs-container{
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .patent-v-patent-header {
            display: flex;
            padding-bottom: 0px;
            justify-content: center;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            padding-bottom: 15px;
            .pvp-wrapper {
              display: flex;
              padding: 6px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 12px;
              background: var(--invalidate-palette-white, #FFF);
              .pvp-patent-number{
                display: flex;
                padding: 5px 8px;
                justify-content: center;
                align-items: center;
                gap: 4px;
              }
              &.prior-art-patent-wrapper{
                box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.05);
                .pvp-patent-number{
                  border-radius: 5px;
                  background: var(--invalidate-palette-red-15, #FEE9ED);
                  color: var(--invalidate-palette-red-dark, #DF1440);
                  text-align: right;
                  font-feature-settings: 'clig' off, 'liga' off;

                  /* App/Mobile/Caption */
                  font-family: Manrope;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 17px;
                  letter-spacing: 1.2px;
                  text-transform: uppercase;
                }
              }
              &.related-patent-wrapper {
                box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.05);
                .pvp-patent-number{
                  border-radius: 5px;
                  background: var(--invalidate-palette-grey-5, #F1F3F3);
                  color: #505154;
                  text-align: right;
                  font-feature-settings: 'clig' off, 'liga' off;

                  /* App/Mobile/Caption */
                  font-family: Manrope;
                  font-size: 12px;
                  font-weight: 800;
                  line-height: 17px;
                  letter-spacing: 1.2px;
                  text-transform: uppercase;
                }
              }
            }

            .vs {
              color: #1F2023;
              font-feature-settings: 'clig' off, 'liga' off;

              /* App/Mobile/Caption */
              font-family: Manrope;
              font-size: 12px;
              font-style: normal;
              font-weight: 800;
              line-height: 17px; /* 141.667% */
              letter-spacing: 1.2px;
              text-transform: uppercase;
            }

          }

        }
        .section-container{
          padding: 8px 10px 0px 10px;
          background-color: #fff;
          border-radius: 0px 14px 0px 0px;
          min-height: 100px;
          .section-title{
            font-family: 'Manrope';
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            margin: 20px 0px;
            letter-spacing: -0.01em;
            display: flex;
            justify-content: space-between;
          }
          .claim-overlap-table{
            #element-number{
              font-family: 'Manrope';
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
            }
            #element-content{
              p{
                font-family: 'Courier';
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: #737373;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 8px 12px;
                gap: 10px;

                width: 306px;

                background: #F5F5F5;
                border-radius: 5px;
              }
            }
            #element-content-overlap{
              p{
                font-family: 'Courier';
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: #737373;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 8px 12px;
                gap: 10px;

                width: 306px;

                background: #FFF1D5;
                border-radius: 5px;
              }
            }
            #reason{
              font-family: 'Inter';
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: -0.01em;
              color: #737373;
            }
          }
          .related-patents-table{
            #patent-info{
              .patent-title{
                font-family: 'Manrope';
                font-weight: 700;
                font-size: 15px;
              }
              .patent-abstract{
                font-family: 'Inter';
                font-weight: 500;
                font-size: 11px;
                line-height: 17px;
                /* or 155% */

                letter-spacing: -0.01em;


                color: #737373;
              }
            }
            #publication-number{
              white-space: nowrap;
              & > span{
                font-family: 'Manrope';
                font-weight: 700;
                font-size: 13px;
                line-height: 17px;
                /* identical to box height, or 131% */

                text-align: right;
                letter-spacing: 0.02em;

                /* Invalidate UI/Contrast Red */

                color: #DF1440;
                padding: 4px 10px 4px 8px;
                height:10px;
                background: linear-gradient(0deg, rgba(251, 15, 66, 0.07), rgba(251, 15, 66, 0.07)), #FFFFFF;
                border-radius: 6px;
              }
            }
            #assignee{
              white-space: nowrap;
              font-family: 'Inter';
              font-weight: 600;
              font-size: 13px;
              color: #202020;
            }
          }
          .disclaimer-area {
            display: flex;
            padding: 26px 16px;
            align-items: center;
            gap: 5px;
            align-self: stretch;
            border-bottom: 1px solid var(--color-palette-grey-15, #DDDEDE);
            .disclaimer-info {
              display: flex;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              .disclaimer-text {
                display: flex;
                height: 39px;
                align-items: flex-start;
                flex: 1 0 0;
                color: var(--color-palette-grey-100, #1F2023);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Manrope;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                p{
                  margin: 0;
                }
                .company{
                  color: #DF1440;
                }
              }
            }
          }
        }
      }
      .claim-selection-menu{
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 30px 0px;
        .navigation-buttons{
          display: flex;
          flex-direction: column;
          margin-top: auto;
          gap: 20px;
          .apply-selection{
            height: 50px;
            /* Invalidate UI/Contrast Red */
            background: #DF1440;
            /* Invalidate UI/Contrast Red */
            border: 1px solid #DF1440;
            box-shadow: 0px 67px 27px rgba(195, 19, 57, 0.03), 0px 38px 23px rgba(195, 19, 57, 0.11), 0px 17px 17px rgba(195, 19, 57, 0.18), 0px 4px 9px rgba(195, 19, 57, 0.21), 0px 0px 0px rgba(195, 19, 57, 0.22);
            border-radius: 8px;
          }
        }
      }
    }
    .prior-art-details-content{
      height: calc(100vh - 10px);
      padding: 10px 0 0 10px;
      border-radius: 25px;
      background: rgba(255, 255, 255, 0.50);
      backdrop-filter: blur(50px);
    }
    .center-flex{
      justify-content: center;
    }
  }
}


.agreement-modal {
  h2{
    font-family: 'Manrope', sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.26px;
    color: #1F2023;
}

h3{
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.216px;
    color: #1F2023;
}


button{
    text-decoration: none;
    transition: all .3s ease-in-out;
}

.button{
    border-radius: 8px;
    padding: 12px 27px;
    border: 1px solid #DF1440;
    background: #DF1440;
    box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.22), 
                0px 4px 9px 0px rgba(195, 19, 57, 0.21), 
                0px 17px 17px 0px rgba(195, 19, 57, 0.18), 
                0px 38px 23px 0px rgba(195, 19, 57, 0.11), 
                0px 67px 27px 0px rgba(195, 19, 57, 0.03),
                0px 105px 29px 0px rgba(195, 19, 57, 0.00);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.16px;
    &:hover{
        color: #FFF;
        box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.32), 
        0px 4px 9px 0px rgba(195, 19, 57, 0.31), 
        0px 17px 17px 0px rgba(195, 19, 57, 0.25), 
        0px 38px 23px 0px rgba(195, 19, 57, 0.25), 
        0px 67px 27px 0px rgba(195, 19, 57, 0.05),
        0px 105px 29px 0px rgba(195, 19, 57, 0.05);
    }
}

.number{
    font-family: 'Manrope', sans-serif;
    font-size: 10px;
    font-weight: 800;
    line-height: 12px; 
    border-radius: 50%;
    background-color: #1F2023;
    color: #FFF;
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -3px;
    margin-right: 8px;
}

// Modal
.modal{
    .modal-dialog{
        max-width: 850px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        .modal-content{
            border-radius: 25px;
            border: 0;
            background: #FFF;
            box-shadow: 0px 0px 0px 0px rgba(31, 39, 52, 0.03), 0px 13px 28px 0px rgba(31, 39, 52, 0.03), 0px 51px 51px 0px rgba(31, 39, 52, 0.03), 0px 115px 69px 0px rgba(31, 39, 52, 0.02), 0px 204px 82px 0px rgba(31, 39, 52, 0.00), 0px 319px 89px 0px rgba(31, 39, 52, 0.00);
            backdrop-filter: blur(16.5px);
            padding: 5px 15px 20px 30px;
            max-height: 90vh;

            .modal-header{
                border-bottom: 1px solid #DDDEDE;
                padding: 15px 0 20px;
                margin-right: 20px;
                display: flex;
                justify-content: space-between;
                button{
                    background-color: initial;
                    border: 0;
                    outline: 0;
                    padding: 0;
                    margin: 0;
                    border-radius: 50%;
                    &:hover{
                        opacity: .5;
                    }
                    &:focus,
                    &:focus-visible,
                    &:focus-within{
                        box-shadow: none;
                    }
                }
            }
            .modal-body{
                padding: 0;
                position: relative;
                .copy-area{
                    padding-bottom: 20px;
                    padding-right: 20px;
                    max-height: 60vh;
                    overflow: scroll;
                    margin: 5px 0;
                    &::-webkit-scrollbar{
                        width: 5px;
                        height: 0;
                    }
                    &::-webkit-scrollbar-track{
                        background-color: #DDDEDE;
                        border-radius: 50px;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: #FB0F42;
                        border-radius: 50px;
                    }
                    h2,h3{
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }
                    h3{
                        margin-top: 30px;
                    }
                    p{
                        color: #505154;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: -0.12px;
                        &.highlight{
                            font-weight: 600;
                        }
                    }
                }
            }
            .modal-footer{
                display: block;
                padding: 0;
                border-top: 1px solid #DDDEDE;
                margin-right: 20px;
                .validation-area{
                    margin: 0;
                    text-align: center;
                    position: relative;
                    padding: 20px 0;
                    &::before{
                        content: "";
                        width: 99%;
                        height: 50px;
                        position: absolute;
                        top: -51px;
                        left: 0;
                        background: linear-gradient(0deg, #FFFFFF 0%, #FFFFFF00 100%);
                    }
                    form{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        label{
                            display: inline-block;
                            margin-bottom: 20px;
                            font-family: 'Manrope', sans-serif;
                            font-size: 17px;
                            font-weight: 600;
                            line-height: 22px;
                            letter-spacing: -0.204px;
                            cursor: pointer;
                        }
                        input[type="checkbox"]{
                            width: 18px;
                            height: 18px;
                            margin-right: 5px;
                            position: relative;
                            top: 3px;
                            filter: saturate(0);
                        }
                        input[type="submit"]{
                            min-width: 320px;
                            box-shadow: 0px 0px 0px 0px rgba(195, 19, 57, 0.22), 
                            0px 4px 9px 0px rgba(195, 19, 57, 0.21), 
                            0px 17px 17px 0px rgba(195, 19, 57, 0.18), 
                            0px 28px 23px 0px rgba(195, 19, 57, 0.11), 
                        }
                        input[type="submit"]:disabled {
                            background-color: #B5B5B6;
                            border: 1px solid #B5B5B6;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}
.modal-backdrop{
    background: rgba(30, 36, 41, 0.80);
    &.show{
        opacity: 1;
    }
}
}